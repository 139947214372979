import "./ServiceCards.css";
import img from "../../Assets/services/Card1.png";
import img2 from "../../Assets/services/Card2.png";
import img3 from "../../Assets/services/Card3.png";
import img4 from "../../Assets/services/Card4.png";
import img5 from "../../Assets/services/Card5.png";
import img6 from "../../Assets/services/Card6.png";
import img7 from "../../Assets/services/Card7.png";
import img8 from "../../Assets/services/Card8.png";
import img9 from "../../Assets/services/Card10.png";
import left from "../../Assets/services/left.png";
import right from "../../Assets/services/right.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const cards = [
  {
    image: img,
    header: "Земельные споры",
    description:
      "Разрешение споров по поводу владения, пользования, распоряжения, определения границ земельных участков  (раздел земельного участка, изменение категории земельного участка, определение порядка пользования участком, нарушение правил добрососедства, захват чужих территорий и т.д.).",
    href: '/land'
  },
  {
    image: img2,
    header: "Наследственные споры",
    description:
      "Раздел имущества между наследниками; признание права на обязательную долю в наследстве; восстановление пропущенного срока для принятия наследства; оспаривание завещания; признание недостойного наследника; иные наследственные споры.",
    href: '/inheritance'
  },
  {
    image: img3,
    header: "Жилищные споры",
    description:
      "Признание/оспаривание прав собственности на жилое помещение; раздел жилого помещения в суде; вселение/выселение; перевод нежилого помещения в жилое и наоборот; признание сделки купли-продажи/дарения квартиры или иного жилья недействительной; иные споры, связанные с жилим недвижимым имуществом.",
    href: '/housing'
  },
  {
    image: img4,
    header: "Медиация",
    description:
      "Оказываем профессиональные услуги медиатора по разрешению споров в досудебном порядке, судебном или исполнительном производствах. Процедура медиации способствует сторонам конфликта принять взаимовыгодное решение.",
    href: '/mediation'
  },
  {
    image: img5,
    header: "Семейные споры",
    description:
      "Расторжение брака; раздел совместно нажитого имущества; взыскание алиментов; определение места жительства детей; определение порядка общения с детьми; лишение/восстановление родительских прав; установление/оспаривание отцовства.",
    href: '/family'
  },
  {
    image: img6,
    header: "Споры по инфобизнесу",
    description:
      "Правовое сопровождение бизнеса, основанного на продаже информации (курсы, семинары, вебинары, тренинги, конференции, коучинги, консалтинги, франшизы и т.д.).",
    href: '/info-business'
  },
  {
    image: img7,
    header: "Споры с гос органами",
    description:
      "Обжалование в вышестоящий государственный орган или суд действий/бездействий должностных лиц.",
    href: '/government'
  },
  {
    image: img8,
    header: "Корпоративные споры",
    description: "Правовое сопровождение деятельности юридических лиц.",
    href: '/corporate'
  },
  {
    image: img9,
    header: "Имущественные споры",
    description:
      "Признание сделки недействительной; возмещение убытков; взыскание суммы задолженности; признание права собственности на имущество; раздел имущества; иные споры, связанные с нежилимым и/или движимым имуществом.",
    href: '/property'
  },
];

function Card({ image, header, description, href, formUp}) {
  return (
    <div className="service-card">
      <img src={image} alt={header} />
      <div className="header">{header}</div>
      <div className="description">{description}</div>
      <div className="service-card-btns">
        <button onClick={formUp} className="b1">
          Обратиться
        </button>
        <Link to={href}>
          <button className="b2">
            Подробнее
          </button>
        </Link>
      </div>
    </div>
  );
}

const Cards = ({ form }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photosPerSlide, setPhotosPerSlide] = useState(4);
  const [cardsli, setCardsli] = useState([]);

  const updatePhotosPerSlide = () => {
    if (window.innerWidth <= 440) {
      setPhotosPerSlide(1); // Mobile view
      let newli = [];
      for (let i = 0; i < 3; i++) {
        let index = (currentIndex + i) % cards.length;
        newli.push(cards[index]);
      }
      setCardsli(newli);
    } else if (window.innerWidth > 440 && window.innerWidth <= 768) {
      setPhotosPerSlide(3); // Tablet view
      let newli = [];
      for (let i = 0; i < 2; i++) {
        let index = (currentIndex + i) % cards.length;
        newli.push(cards[index]);
      }
      setCardsli(newli);
    } else {
      setPhotosPerSlide(4);
      let newli = [];
      for (let i = 0; i < 4; i++) {
        let index = (currentIndex + i) % cards.length;
        newli.push(cards[index]);
      }
      setCardsli(newli);
    }
  };

  useEffect(() => {
    updatePhotosPerSlide(); // Check on initial render
    window.addEventListener("resize", updatePhotosPerSlide); // Add event listener for window resize
    return () => window.removeEventListener("resize", updatePhotosPerSlide); // Cleanup on unmount
  }, []);

  const totalCards = cards.length;

  const nextSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex < totalCards - photosPerSlide) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const visibleCards = cards.slice(currentIndex, currentIndex + photosPerSlide);

  return (
    <div className="carousel-container">
      <div className="carousel">
        {visibleCards.slice().map((photo, index) => (
          <Card
            key={index}
            image={photo.image}
            header={photo.header}
            description={photo.description}
            formUp={form}
            href={photo.href}
          />
        ))}
      </div>
      <div className="carousel-buttons">
        <button onClick={nextSlide}>
          <img src={left} />
        </button>
        <div className="carousel-indicators">
          {Array.from({
            length: totalCards - photosPerSlide + 1,
          }).map((_, index) => (
            <div
              key={index}
              className={`indicator ${
                index === Math.floor(currentIndex) ? "active" : ""
              }`}
            />
          ))}
        </div>
        <button onClick={prevSlide}>
          <img src={right} />
        </button>
      </div>
    </div>
  );
};

export default Cards;
