import "./Services.css";
import Cards from "./Cards";
import line from "../../Assets/services/line.png";
import img from "../../Assets/services/Card1.png";

import left from "../../Assets/services/left.png";
import right from "../../Assets/services/right.png";
import React, { useState, useEffect } from "react";

import img1 from "../../Assets/ug1.png";
import img2 from "../../Assets/ug2.png";
import img3 from "../../Assets/ug3.png";
import { Link } from "react-router-dom";

export default function Services({ updateForm, updateList }) {
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    if (index < 2) {
      setIndex(index + 1);
    }
  };

  const prevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <>
      <div className="services" id="Services">
        <div className="t1">Наши Услуги</div>
        <div className="t2">
          МЫ <span>эксперты</span> В СФЕРЕ <span>УГОЛОВНОГО ПРАВА</span> и{" "}
          <span>ГРАЖДАНСКОГО ПРАВА</span>
        </div>

        <div className="t3">С чем мы можем вам помочь?</div>
        <div className="t4">Гражданские дела</div>

        <div className="buttons">
          <Link to='/services'>
            <button className="t5">
              Смотреть все услуги
            </button>
          </Link>
          <button onClick={updateList} className="t6">
            Список
          </button>
        </div>

        <Cards form={updateForm}></Cards>
        <img src={line} className="line" />
        <div className="t7">Уголовные дела</div>

        <div className="carousel number2">
          <div className={index == 0 ? "service-card" : "service-card hide"}>
            <img src={img1} />
            <div className="header">Экономическое право</div>
            <div className="description">
              • Незаконное предпринимательство;
              <br />
              • Экономическая контрабанда;
              <br />• Уклонение от уплаты таможенных пошлин, сборов, налогов и
              других обязательных платежей в бюджет;
              <br />
              • Преднамеренное банкротство;
              <br />
              • Легализация денег и/или иногоимущества, добытого преступным
              путем;
              <br />• Иные преступления в сфере экономической деятельности
            </div>
            <div className="service-card-btns">
              <button onClick={updateForm} className="b1">
                Обратиться
              </button>
              <Link to='/economic'>
                <button className="b2">
                  Подробнее
                </button>
              </Link>
            </div>
          </div>
          <div className={index == 1 ? "service-card" : "service-card hide"}>
            <img src={img2} />
            <div className="header">Коррупционное право</div>
            <div className="description">
              • Превышение должностных полномочий;
              <br />• Незаконное участие в предпринимательской деятельности;
              <br />• Получение/дача взятки;
              <br />• Иные коррупционные преступления
            </div>
            <div className="service-card-btns">
              <button onClick={updateForm} className="b1">
                Обратиться
              </button>
              <Link to='/corruption'>
                <button className="b2">
                  Подробнее
                </button>
              </Link>
            </div>
          </div>
          <div className={index == 2 ? "service-card" : "service-card hide"}>
            <img src={img3} />
            <div className="header">Преступления против собственности</div>
            <div className="description">
              • кража;
              <br />
              • мелкое хищение;
              <br />
              • мошенничество;
              <br />
              • грабеж;
              <br />
              • разбой;
              <br />
              • вымогательство;
              <br />
              • умышленное уничтожение или повреждение чужого имущества;
              <br />• иные преступления против собственности
            </div>
            <div className="service-card-btns">
              <button onClick={updateForm} className="b1">
                Обратиться
              </button>
              <Link to='/property-crime'>
                <button className="b2">
                  Подробнее
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="serv-bbts">
          <button onClick={prevSlide}>
            <img src={left} />
          </button>
          <div className="service-num2-indicators">
            <div className={index == 0 ? "indicator show" : "indicator"} />
            <div className={index == 1 ? "indicator show" : "indicator"} />
            <div className={index == 2 ? "indicator show" : "indicator"} />
          </div>
          <button onClick={nextSlide}>
            <img src={right} />
          </button>
        </div>
      </div>
    </>
  );
}
