import mem1 from "../Assets/team/member1.svg";
import mem2 from "../Assets/team/member2.svg";
import mem3 from "../Assets/team/member3.svg";
import mem5 from "../Assets/team/member5.svg";
import left from "../Assets/team/lb.png";
import right from "../Assets/team/rb.png";
import React, { useState } from "react";
import "../Style/Team.css";

const TeamMember = ({ image, position, name }) => {
  return (
    <div className="team-member">
      <img src={image} />
      <div className="k1">{position}</div>
      <div className="k2">{name}</div>
    </div>
  );
};

export default function Team() {
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    if (index < 3) {
      setIndex(index + 1);
    }
  };

  const prevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div className="team">
      <div className="content">
        <div className="g1">наша команда</div>
        <div className="g2">Познакомьтесь с нашей командой!</div>
        <div className="g3">
          Наша команда объединяет опытных юристов с глубокими знаниями в
          различных областях права. Мы готовы защитить ваши интересы и
          предложить эффективные решения.
        </div>

        <div className="team-members">
          <div className={index == 0 ? "" : "hide"}>
            <TeamMember
              image={mem3}
              position="ОСНОВАТЕЛЬ КОМПАНИИ, РУКОВОДИТЕЛЬ"
              name="Мухамбеткалиев Данияр"
              hide={index == 2 ? "hide" : ""}
            ></TeamMember>
          </div>
          <div className={index == 1 ? "" : "hide"}>
            <TeamMember
              image={mem2}
              position="АДВОКАТ"
              name="Юсубалиев Нурлыбек"
            ></TeamMember>
          </div>
          <div className={index == 2 ? "" : "hide"}>
            <TeamMember
              image={mem1}
              position="КОРПОРАТИВНЫЙ ЮРИСТ"
              name="Идрисова Карлыгаш"
            ></TeamMember>
          </div>
          <div className={index == 3 ? "" : "hide"}>
            <TeamMember
              image={mem5}
              position="СТАЖЕР"
              name="Кодыга Амир"
            ></TeamMember>
          </div>
        </div>

        <div className="team-buttons">
          <button onClick={prevSlide}>
            <img src={left} />
          </button>
          <div className="team-indic">
            <div className={index == 0 ? "indic show" : "indic"} />
            <div className={index == 1 ? "indic show" : "indic"} />
            <div className={index == 2 ? "indic show" : "indic"} />
            <div className={index == 3 ? "indic show" : "indic"} />
          </div>
          <button onClick={nextSlide}>
            <img src={right} />
          </button>
        </div>
      </div>
    </div>
  );
}
