import "../Style/Contacts.css";
import { useState } from "react";
import email from "../Assets/Contacts/email.svg";
import loc from "../Assets/Contacts/loc.svg";
import whats from "../Assets/Contacts/whats.svg";
import inst from "../Assets/Contacts/image 69 (Traced).svg";

export default function Contacts() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    type: "обращение",
  });

  const [status, setStatus] = useState('initial')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatus('pending')
    fetch("submit.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          setStatus('fail')
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        setStatus('success')
        setFormData({
          name: "",
          phone: "",
          message: "",
        });
        setTimeout(() => {
          setStatus('initial')
        }, 1500)
      })
      .catch((error) => {
        setStatus('fail')
        setTimeout(() => {
          setStatus('initial')
        }, 1500)
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="contacts" id="Contacts">
      <div className="left">
        <div className="c1">как нас найти</div>
        <div className="c2">
          Не стесняйтесь обращаться к нам в любое время. Мы ответим в кратчайшие
          сроки!
        </div>
        <form onSubmit={handleSubmit}>
          <input
            class="num-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={formData.name ? "" : "Ваше Имя"} // Clear placeholder when typing
            required
          />
          <div className="line"></div>

          <input
            class="num-input"
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder={"Номер телефона"}
            required
          />
          <div className="line"></div>

          <input
            class="mes-input"
            name="message"
            value={formData.message}
            required
            onChange={handleChange}
            placeholder={formData.message ? "" : "Обращение"}
          />
          <div className="line"></div>

          <button 
            type="submit"
            style={{
              background: status === 'fail'? '#F95651': 
                          status === 'success'? '#60CF60': 
                          status === 'pending'? '#FCD229': 
                          '#6076CF'
            }}
            disabled={status === 'pending'}
          >{
            status === 'pending'? 'Отправка...':
            status === 'success'? 'Отправлено!':
            status === 'fail'? 'Ошибка, повторить попытку':
            'Отправить'
          }</button>
        </form>
      </div>
      <div className="right">
        <div className="rcon">
          <div className="black-box"></div>
          <div className="r1">ИНФО</div>
          <div className="r2">
            <a>
              <img src={email} />
            </a>
            <a>tvoepravo.kz@gmail.com</a>
          </div>
          <div className="r2">
            <a href="https://2gis.kz/astana/geo/70000001018104949">
              <img src={loc} />
            </a>
            <a
              className="r3"
              href="https://2gis.kz/astana/geo/70000001018104949"
            >
              г. Астана, пр-т Б.Момышұлы, 12 БЦ "Меруерт", оф. 103
            </a>
          </div>
          <div className="r2">
            <a href="https://wa.me/77051916994?text=">
              <img src={whats} />
            </a>
            <a href="https://wa.me/77051916994?text=">+7 705 191 6994</a>
          </div>
          <div className="r2">
            <a href="https://www.instagram.com/daniyar.mukhambetkaliyev?igsh=emYydmx2dG5kYmJh">
              <img src={inst} />
            </a>
            <a
              className="r4"
              href="https://www.instagram.com/daniyar.mukhambetkaliyev?igsh=emYydmx2dG5kYmJh"
            >
              daniyar.mukhambetkaliyev
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
