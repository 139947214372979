import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image9.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Government({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page gosorgan">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">споры с государственными органами</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Споры с государственными органами могут возникать по различным
              причинам, включая налоговые претензии, нарушения регуляторных
              требований, административные санкции или вопросы лицензирования.
              Юрист, занимающийся такими спорами, оказывает помощь в подготовке
              и подаче жалоб, оспаривании административных решений и
              представлении интересов клиента в судебных разбирательствах. Он
              анализирует действующее законодательство, помогает разработать
              стратегию защиты, взаимодействует с государственными органами для
              поиска компромиссных решений и обеспечивает соблюдение прав
              клиента. Цель юриста — эффективно разрешить споры, минимизировать
              негативные последствия и защитить интересы клиента в отношениях с
              государственными учреждениями.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации по административному праву:</span> Оказание
            юридических советов по вопросам, связанным с административными
            процедурами, регуляторными требованиями и правами клиента при
            взаимодействии с государственными органами.
          </div>
          <div className="lst">
            <span>2) Оспаривание административных актов:</span> Подготовка и
            подача жалоб или апелляций против решений или действий
            государственных органов, таких как штрафы, санкции или отказ в
            выдаче лицензий.
          </div>
          <div className="lst">
            <span>3) Представительство в судебных разбирательствах:</span>{" "}
            Представление интересов клиента в судах по делам, связанным с
            административными спорами, включая подачу исков и участие в судебных
            заседаниях.
          </div>
          <div className="lst">
            <span>4) Юридическая помощь в налоговых спорах:</span>
            Консультирование и защита интересов клиента в спорах с налоговыми
            органами, включая проверку налоговых деклараций, обжалование
            налоговых решений и штрафов.
          </div>
          <div className="lst">
            <span>5) Сопровождение лицензирования и разрешений:</span> Помощь в
            получении и поддержании лицензий и разрешений, а также в оспаривании
            отказов или приостановлений.
          </div>
          <div className="lst">
            <span>6) Взаимодействие с регуляторными органами:</span> Помощь в
            подготовке и подаче необходимых документов, ответов на запросы и
            ведение переговоров с государственными органами.
          </div>
          <div className="lst">
            <span>7) Разработка стратегий соблюдения законодательства:</span>{" "}
            Консультирование по вопросам соблюдения нормативных актов и
            предотвращения нарушений, которые могут привести к спорам с
            государственными органами.
          </div>
          <div className="lst">
            <span>8) Оценка и минимизация рисков:</span> Проведение правового
            анализа для выявления потенциальных рисков в деятельности клиента,
            связанных с государственными органами, и разработка мер по их
            минимизации.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
