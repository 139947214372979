import "../Style/NavBar.css";
import { useState } from "react";
import { Link } from "react-scroll";

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="desk">
        <ul>
          <li>
            <Link to="Main" spy={true} smooth={true} offset={0} duration={500}>
              На главную
            </Link>
          </li>
          <li>
            <Link to="About" spy={true} smooth={true} offset={0} duration={700}>
              О нас
            </Link>
          </li>
          <li>
            <Link
              to="Services"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Наши Услуги
            </Link>
          </li>
          <li>
            <Link
              to="Feedbacks"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Отзывы
            </Link>
          </li>
          <li>
            <Link
              to="Contacts"
              spy={true}
              smooth={true}
              offset={0}
              duration={1500}
            >
              Контакты
            </Link>
          </li>
        </ul>
      </div>

      <div className={menuOpen ? "mobile open" : "mobile"}>
        <ul>
          <li>
            <div
              className={menuOpen ? "triple open" : "triple"}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className="tr1"></div>
              <div className="tr2"></div>
              <div className="tr3"></div>
            </div>
          </li>
          <li>
            <Link to="Main" spy={true} smooth={true} offset={0} duration={1000}>
              На главную
            </Link>
          </li>
          <li>
            <Link
              to="About"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              О нас
            </Link>
          </li>
          <li>
            <Link
              to="Services"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Наши Услуги
            </Link>
          </li>
          <li>
            <Link
              to="Feedbacks"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              Отзывы
            </Link>
          </li>
          <li>
            <Link
              to="Contacts"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              Контакты
            </Link>
          </li>
          <li>
            <a href="https://wa.me/77051916994?text=">+7 705 191 6994</a>
          </li>
        </ul>
      </div>
    </>
  );
}
