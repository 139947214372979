import star from "../../Assets/feefbacks/star.png";
import gis from "../../Assets/feefbacks/2gis.png";
import left from "../../Assets/GGHHH.png";
import right from "../../Assets/GGHH.png";
import React, { useState, useEffect } from "react";

const cards = [
  {
    header: "Надежда Школьная",
    description:
      "Специалист своего дела, обратилась и через два месяца все готово, спасибо вам огромное!",
  },
  {
    header: "Ирина леденкова",
    description:
      "Мы выиграли! Я эксперт мягкой ниши. Очень сложно доказать, что ты сделал работу качественно. И ты это сделал. Данияр сам разобрался детально в моем продукте. И доказал, что услуга была выполнена качественно. Всем экспертам, чьими услугами пользуются и не хотят после платить, рекомендую Данияра. Не нужно думать, не нужно думать что невозможно отстоять ваши права. Клиент не всегда прав! Данияр благодарю, за качественно проведенное дело!",
  },
  {
    header: "Daniel Leinad",
    description: "Хороший юрист, хороший человек 5/5",
  },

  {
    header: "karlygash idrisova",
    description:
      "Хочу поблагодарить юристов вашей компании, особенно Данияра за успешное решение моего вопроса. Очень быстро и профессионально решил мою проблему, которую до них несколько юристов решали больше полугода.",
  },
  {
    header: "Анара Нургалиева",
    description:
      "Благодарим Даниэля Мухамбеткалиева за оперативное сопровождение! Очень грамотный профессионал, спасли нас от мошенников, я рекомендую обращаться сюда при любых болезненных вопросах",
  },
  {
    header: "Дмитрий Каткаев",
    description: "Хорошие специалисты знающие свое дело!",
  },
  {
    header: "Иван лисица",
    description:
      "Хочу выразить благодарность за профессиональную помощь, помог в очень тяжелом деле, там где другие не смогли, всем советую обращаться именно к нему, мастер своего дела, спасибо большое!",
  },
  {
    header: "karlygash idrisova",
    description:
      "Хочу поблагодарить юристов вашей компании, особенно Данияра за успешное решение моего вопроса. Очень быстро и профессионально решил мою проблему, которую до них несколько юристов решали больше полугода.",
  },
  {
    header: "Maksat Temirbek",
    description: "Все грамотно и быстро решили нашу проблему",
  },
  {
    header: "Taker Ula",
    description:
      "От всей души хочется поблагодарить Данияра Капияновича иМақсат Нұрланұлы за высокое знание своего дела и профессионализм. За неоднократную юридическую помощь и поддержку оказанную вами решении земельного вопроса. Профессиональных успехов вам в вашей карьерной деятельности",
  },
  {
    header: "Арман Жумагалиев",
    description:
      "Настоящие профессионалы, помогли решить тяжелый вопрос с регистрацией, который усложнялся сжатыми сроками. Провели консультацию, разложили все по полочкам, и сопровождали на всем пути. Советую всем обращаться именно сюда, какая бы юридическая помощь не понадобилась. Огромное вам спасибо!",
  },
  {
    header: "Найля Черная",
    description:
      "Хочу поблагодарить вас Данияр и сказать огромное спасибо 1.5 года я судилась и меняла адвокатов. За это время было много чего, но этот человек- Мастер своего дела. Я помню как в первый раз вы сказали мы выиграем, просто нужно подобжать, и вы сдержали свое слово. До сих пор не верю что мы действительно выиграли это непростое, я бы даже сказала безнадежное дело. Еще раз благодарю вас за ваше терпение и четко выполнять свою работу. Теперь буду всем рекомендовать вас :)",
  },
];

function Card({ name, text }) {
  return (
    <div className="gis-card">
      <img src={gis} className="gis-img" />
      <div className="header">{name}</div>
      <div className="text">{text}</div>
      <div className="stars">
        <img src={star} />
        <img src={star} />
        <img src={star} />
        <img src={star} />
        <img src={star} />
      </div>
    </div>
  );
}

const GisCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photosPerSlide, setPhotosPerSlide] = useState(4);
  const [cardsli, setCardsli] = useState([]);

  // Update photosPerSlide based on window size
  const updatePhotosPerSlide = () => {
    if (window.innerWidth <= 440) {
      setPhotosPerSlide(1); // Mobile view
    } else if (window.innerWidth <= 768) {
      setPhotosPerSlide(2); // Tablet view
    } else {
      setPhotosPerSlide(4);
    }
  };

  useEffect(() => {
    updatePhotosPerSlide(); // Check on initial render
    window.addEventListener("resize", updatePhotosPerSlide); // Add event listener for window resize
    return () => window.removeEventListener("resize", updatePhotosPerSlide); // Cleanup on unmount
  }, []);

  const totalCards = cards.length;

  const nextSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex < totalCards - photosPerSlide) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const visibleCards = cards.slice(currentIndex, currentIndex + photosPerSlide);

  return (
    <div className="feedback-cards">
      <div className="cards">
        {visibleCards.slice().map((photo, index) => (
          <Card name={photo.header} text={photo.description} />
        ))}
      </div>

      <div className="carousel-buttons feed">
        <button onClick={nextSlide}>
          <img src={left} />
        </button>
        <div className="carousel-indicators">
          {Array.from({
            length: totalCards - photosPerSlide + 1,
          }).map((_, index) => (
            <div
              key={index}
              className={`indicator ${
                index === Math.floor(currentIndex) ? "active" : ""
              }`}
            />
          ))}
        </div>
        <button onClick={prevSlide}>
          <img src={right} />
        </button>
      </div>
    </div>
  );
};

export default GisCards;
