import React, { useState, useEffect } from "react";
import left from "../Assets/services/left.png";
import right from "../Assets/services/right.png";
import "../Style/Certificates.css";

import cert1 from "../Assets/certs/АЛГЫС ХАТ 53 ШКОЛА-ЛИЦЕЙ 2023 ГОД_page-0001.png";
import cert2 from "../Assets/certs/БЛАГОДАРСТВЕННОЕ ПИСЬМО ДЕКАНАТ ИНЖЕНЕРНО-ГУММАНИТОРНОГО ФАКУЛЬТЕТА_page-0001.png";
import cert3 from "../Assets/certs/БЛАГОДАРСТВЕННОЕ ПИСЬМО ОТ ТОО СЕГРАЛИ ТУРКЕСТАН_page-0001.png";
import cert4 from "../Assets/certs/БЛАГОДАРСТВЕННОЕ ПИСЬМО ОФ ОТЦЫ В ДЕЛЕ 15.05.2021_page-0001.png";
import cert5 from "../Assets/certs/ГРАМОТА ПСИХОЛОГИЯ_page-0001.png";
import cert6 from "../Assets/certs/КАТЫЛЫМ БЕЛГЫСЫ 31.05.2012 ТУРЦИЯ_page-0001.png";
import cert7 from "../Assets/certs/ОТЗЫВ ТОО АСТАНАСТРОЙСЕРВИС-1 17.08.2015_page-0001.png";
import cert8 from "../Assets/certs/СЕРТИФИКАТ АССОЦИАЦИЯ ЦЕНТР РАЗВИТИЯ МЕДИАЦИИ_page-0001.png";

const cards = [
  { cert: cert1 },
  { cert: cert2 },
  { cert: cert3 },
  { cert: cert4 },
  { cert: cert5 },
  { cert: cert6 },
  { cert: cert7 },
  { cert: cert8 },
];

function Cert({ certt }) {
  return <img src={certt} />;
}

const Certificates = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photosPerSlide, setPhotosPerSlide] = useState(2);

  // Update photosPerSlide based on window size
  const updatePhotosPerSlide = () => {
    if (window.innerWidth <= 800) {
      setPhotosPerSlide(1); // Tablet view
    } else {
      setPhotosPerSlide(2); // Default to 3 on larger screens
    }
  };

  useEffect(() => {
    updatePhotosPerSlide(); // Check on initial render
    window.addEventListener("resize", updatePhotosPerSlide); // Add event listener for window resize
    return () => window.removeEventListener("resize", updatePhotosPerSlide); // Cleanup on unmount
  }, []);

  const totalCards = cards.length;

  const nextSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex < totalCards - photosPerSlide) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const visibleCards = cards.slice(currentIndex, currentIndex + photosPerSlide);

  return (
    <div className="certificates ">
      <div className="header">Сертификаты</div>
      <div className="certificates-cards">
        {visibleCards.map((photo) => (
          <Cert certt={photo.cert} />
        ))}
      </div>
      <div className="certificates-buttons">
        <button onClick={nextSlide}>
          <img src={left} />
        </button>
        <div className="indss">
          {Array.from({
            length: totalCards - photosPerSlide + 1,
          }).map((_, index) => (
            <div
              key={index}
              className={`indiss ${index === currentIndex ? "active" : ""}`}
            />
          ))}
        </div>
        <button onClick={prevSlide}>
          <img src={right} />
        </button>
      </div>
    </div>
  );
};

export default Certificates;
