import { Link } from "react-router-dom";
import "../Style/ServList.css";

export default function ServList() {
  return (
    <div className="serv-list">
      <div className="srl1">Гражданские дела</div>
      <div className="list">
        <Link to='/land'>
          <button className="litem">
            Земельные споры
          </button>
        </Link>
        <Link to='/inheritance'>
          <button className="litem">
            Наследственные споры
          </button>
        </Link>
        <Link to='/housing'>
          <button className="litem">
            Жилищные споры
          </button>
        </Link>
        <Link to='/mediation'>
          <button className="litem">
            Медиация
          </button>
        </Link>
        <Link to='/family'>
          <button className="litem">
            Семейные споры
          </button>
        </Link>
        <Link to='/info-business'> 
          <button className="litem">
            Споры по инфобизнесу
          </button>
        </Link>
        <Link to='/government'>
          <button className="litem">
            Споры с государствеными органами
          </button>
        </Link>
        <Link to='corporate'>
          <button className="litem">
            Корпоративные споры
          </button>
        </Link>
        <Link to='property'>
          <button className="litem">
            Имущественные споры
          </button>
        </Link>
      </div>
      <div className="srl2">уголовные дела</div>
      <div className="list">
        <Link to='economic'>
          <button className="litem">
            Экономическое право
          </button>
        </Link>
        <Link to='corruption'>
          <button className="litem">
            Коррупционное право
          </button>
        </Link>
        <Link to='property-crime'>
          <button className="litem">
            Преступления против собственности
          </button>
        </Link>
      </div>
    </div>
  );
}
