import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image6.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Mediation({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page page6">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">Медиации</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Медиация — это процесс разрешения споров, при котором нейтральная
              третья сторона, медиатор, помогает конфликтующим сторонам найти
              взаимоприемлемое решение без необходимости обращения в суд. Юрист,
              занимающийся медиацией, играет ключевую роль в этом процессе,
              помогая сторонам разобраться в сути конфликта, разработать
              стратегии для переговоров и обеспечить, чтобы достигнутое
              соглашение соответствовало юридическим требованиям. Он
              обеспечивает конструктивный диалог, помогает сбалансировать
              интересы всех сторон и стремится к достижению справедливого и
              удовлетворительного решения для всех участников.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации по правовым вопросам:</span> Предоставление
            юридических советов по различным вопросам, таким как соблюдение
            законодательства, права и обязанности сторон, и правовая оценка
            рисков.
          </div>
          <div className="lst">
            <span>2) Составление и анализ договоров:</span> Подготовка, проверка
            и редактирование договоров и соглашений, чтобы они соответствовали
            законодательству и защищали интересы клиента.
          </div>
          <div className="lst">
            <span>3) Представительство в суде:</span> Представление интересов
            клиента в судебных разбирательствах, включая подготовку
            процессуальных документов, выступление в суде и защита прав клиента.
          </div>
          <div className="lst">
            <span>4) Разрешение споров:</span> Помощь в разрешении споров через
            переговоры, медиацию или арбитраж, направленная на достижение
            взаимоприемлемых решений и соглашений.
          </div>
          <div className="lst">
            <span>5) Юридическое сопровождение сделок:</span> Консультирование и
            сопровождение при проведении сделок, таких как купля-продажа,
            слияния и поглощения, и другие бизнес-операции.
          </div>
          <div className="lst">
            <span>6) Разработка юридических документов:</span> Составление
            различных юридических документов, таких как исковые заявления,
            контракты, уставы организаций и прочие необходимые бумаги.
          </div>
          <div className="lst">
            <span>7) Корпоративное консультирование:</span> Консультирование по
            вопросам корпоративного управления, налогового планирования,
            соблюдения корпоративных норм и управления рисками.
          </div>
          <div className="lst">
            <span>8) Защита прав интеллектуальной собственности:</span> Помощь в
            регистрации и защите патентов, товарных знаков, авторских прав и
            других объектов интеллектуальной собственности, а также разрешение
            споров в этой области.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
