import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image5.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Housing({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">Жилищные споры</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юрист по жилищным спорам специализируется на решении вопросов,
              связанных с правами на жилую недвижимость. Он предоставляет
              юридическую помощь в вопросах признания или оспаривания прав
              собственности, раздела жилых помещений, а также в вопросах
              вселения и выселения. Юрист также занимается вопросами перевода
              помещений между статусами жилого и нежилого, а также признанием
              сделок купли-продажи или дарения жилья недействительными. Его
              работа включает разрешение иных споров, связанных с жилой
              недвижимостью, обеспечивая правовую защиту интересов клиентов.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>
              1) Признание или оспаривание прав собственности на жилое
              помещение.
            </span>
          </div>
          <div className="lst">
            <span>2) Раздел жилого помещения в судебном порядке.</span>
          </div>
          <div className="lst">
            <span>3) Вселение или выселение граждан из жилого помещения.</span>
          </div>
          <div className="lst">
            <span>
              4) Перевод нежилого помещения в статус жилого и наоборот.
            </span>
          </div>
          <div className="lst">
            <span>
              5) Признание сделки купли-продажи или дарения квартиры
              недействительной.
            </span>
          </div>
          <div className="lst">
            <span>
              6) Консультирование по вопросам жилищного законодательства и прав
              на недвижимость.
            </span>
          </div>
          <div className="lst">
            <span>
              7) Разработка и составление договоров и соглашений, связанных с
              жилыми помещениями.
            </span>
          </div>
          <div className="lst">
            <span>
              8) Представление интересов клиента в суде по жилищным делам.
            </span>
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
