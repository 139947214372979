import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image10.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Property({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page imuss">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">имущественные (Гражданские) споры</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Имущественные споры охватывают широкий спектр юридических
              вопросов, связанных с правами на имущество и его распределением.
              Юрист, занимающийся такими делами, предоставляет консультации по
              вопросам признания сделок недействительными, возврата или
              компенсации убытков, а также вопросам, связанным с разделом
              имущества и признанием права собственности. Работа юриста включает
              сбор и анализ документов, подготовку исковых заявлений и
              представление интересов клиентов в судах. Эффективное управление
              имущественными спорами требует глубокого понимания
              законодательства и опыта в решении подобных дел, чтобы защитить
              интересы клиента и достичь наилучшего возможного исхода.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации по сделкам с недвижимостью: </span>Помощь в
            интерпретации и оформлении сделок, проверка законности и условий
            договоров.
          </div>
          <div className="lst">
            <span>2) Оспаривание сделок: </span>Предоставление услуг по
            признанию сделок недействительными из-за обмана, принуждения или
            нарушения законодательства.
          </div>
          <div className="lst">
            <span>3) Возврат и компенсация убытков: </span>Помощь в получении
            компенсации за ущерб, причиненный в результате недобросовестных
            действий или нарушений условий договоров.
          </div>
          <div className="lst">
            <span>4) Урегулирование споров по долгам: </span>Помощь в вопросах
            связанных с задолженностями, включая переговоры и судебное
            разбирательство по взысканию долгов.
          </div>
          <div className="lst">
            <span>5) Раздел имущества: </span>Юридическая помощь в делах о
            разделе совместно нажитого имущества, в том числе в рамках
            бракоразводных процессов.
          </div>
          <div className="lst">
            <span>6) Признание права собственности: </span>Содействие в
            оформлении прав собственности на недвижимость и другое имущество,
            решение споров о правах владения.
          </div>
          <div className="lst">
            <span>7) Споры по наследству: </span>Предоставление юридических
            услуг по вопросам наследования, включая оспаривание завещаний и
            управление наследственными массами.
          </div>
          <div className="lst">
            <span>8) Защита в судах: </span>Представление интересов клиента в
            судебных процессах по имущественным спорам, включая подготовку всех
            необходимых процессуальных документов и защиту в суде.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
