import "./Feedbacks.css";
import GisCards from "./GisCards";

export default function Feedbacks() {
  return (
    <div className="feedbacks" id="Feedbacks">
      <div className="content">
        <div className="f1">Отзывы в 2гис</div>
        <div className="f2">Что о нас думают наши клиенты</div>
        <GisCards></GisCards>
      </div>
    </div>
  );
}
