import vesi from "../Assets/about/about-img.png";
import ach1 from "../Assets/about/Frame 11.svg";
import ach2 from "../Assets/about/Frame 13.svg";
import ach3 from "../Assets/about/Frame 12.svg";
import ach4 from "../Assets/about/Frame 9.svg";
import ach5 from "../Assets/about/Frame 8.svg";
import "../Style/About.css";

export default function About() {
  return (
    <div className="about">
      <img src={vesi} className="vesi" />
      <div className="about-right">
        <div className="about-head">немного о нас</div>
        <ul>
          <li>• Полное юридическое сопровождение</li>
          <li>• Квалифицированная юридическая помощь</li>
          <li>• Долгосрочное сотрудничество</li>
          <li>• 93% выигранных дел</li>
          <li>• Представительства в 5-ти городах Казахстана</li>
        </ul>
        <div className="achs">
          <img src={ach1} />
          <img src={ach2} className="ach2" />
          <img src={ach3} />
          <img src={ach4} className="ach4" />
          <img src={ach5} />
        </div>
      </div>
    </div>
  );
}
