import "../Style/AboutCompany.css";
import aboutComp from "../Assets/aboutcompany/about-company.svg";

export default function AboutCompany({ updateForm }) {
  const submit = () => {
    updateForm();
  };

  return (
    <div className="about-company" id="About">
      <div className="content flex">
        <div className="t1 show">О нашей компании</div>
        <div className="about-company-left">
          <div className="t1">О нашей компании</div>
          <div className="t2 p1">
            Наша компания оказывает юридические услуги физическим и юридическим
            лицам. Средний опыт работы участников нашей команды составляет{" "}
            <span>более 15 лет.</span> Мы делаем акцент на качестве оказания
            услуг и максимально выгодном результате для клиента. Каким бы
            сложным ни был Ваш вопрос мы найдем решение! Наша цель - стать
            лучшей компанией в сегменте предоставления юридических услуг людям и
            бизнесу.
          </div>

          <div className="t2 p2">
            <span>
              В своей деятельности мы придерживаемся следующих принципов работы:
            </span>
          </div>

          <div className="t2 p3">
            <span>1. Ценность каждого клиента.</span> Мы ценим каждого
            обратившегося к нам за помощью и понимаем, что лучшая реклама - это
            Ваши рекомендации.
          </div>

          <div className="t2 p3">
            <span>2. Качество оказания услуг</span> - поддержание высокого
            уровня сервиса одна из главных наших задач.
          </div>

          <div className="t2 p3">
            <span>3. Честность и конфиденциальность</span> - обещать победу в
            проигрышном деле не в наших правилах.
          </div>

          <div className="t2 p3">
            <span>4. Профессионализм</span> - все наши юристы на регулярной
            основе повышают свою квалификацию.
          </div>

          <button onClick={submit} className="t3">
            Оставить Заявку
          </button>
        </div>
        <img src={aboutComp} />
      </div>
    </div>
  );
}
