import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image7.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Corporate({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page">
        <div className="content">
          
          <a href="/"><img src={logo} className="logo" /></a>
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">корпоративные споры</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юрист по корпоративным спорам занимается разрешением конфликтов,
              возникающих между компаниями, внутри организаций, или между
              бизнес-партнерами. Корпоративные споры могут касаться различных
              аспектов деловой деятельности, включая нарушения договорных
              обязательств, корпоративное управление, права акционеров,
              конкуренцию и интеллектуальную собственность. Юрист по
              корпоративным спорам работает на стыке юридической и бизнес-сфер,
              обеспечивая защиту интересов клиента и способствуя сохранению
              деловых отношений. Его работа требует глубокого понимания
              законодательства, навыков переговоров и стратегического мышления.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации и анализ дела:</span> Проведение первичных
            консультаций и изучение фактов для понимания сути спора и оценки
            рисков.
          </div>
          <div className="lst">
            <span>2) Разработка стратегии:</span> Совместная разработка
            стратегии с клиентом для юридического и коммерческого разрешения
            спора.
          </div>
          <div className="lst">
            <span>3) Переговоры и медиация:</span> Ведение переговоров и участие
            в медиации для достижения взаимовыгодного соглашения.
          </div>
          <div className="lst">
            <span>4) Судебное разбирательство:</span> Подготовка и подача исков
            в суд, представление интересов клиента в судебных заседаниях.
          </div>
          <div className="lst">
            <span>5) Защита прав интеллектуальной собственности:</span> Защита
            патентов, товарных знаков и авторских прав в случае нарушений.
          </div>
          <div className="lst">
            <span>6) Корпоративное управление:</span> Разрешение споров,
            связанных с управлением компанией, включая конфликты между
            акционерами.
          </div>
          <div className="lst">
            <span>7) Исполнение судебных решений:</span> Контроль за исполнением
            судебных решений и защита прав клиента.
          </div>
          <div className="lst">
            <span>8) Консультации по предотвращению будущих споров:</span>{" "}
            Разработка рекомендаций для минимизации рисков и предотвращения
            повторных конфликтов.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
